<template>
    <v-app id="bg-app">
        
        <img src="@/assets/images/bg_box_top.png" class="img-top" width="100%">
        <img src="@/assets/images/product.png" class="bg-center">
        <div class="text-wrap">


            <b-button
                pill
                to=""
                size="sm"
                class="btn_show_product">                                            
                <span class="text_btn">
                    UNBOX NOW
                </span>
            </b-button>

        </div>
        
        <img src="@/assets/images/bg_box_bottom.png" class="bg-buttom" width="100%">
        <div class="bg-frond"></div>
    </v-app>
</template>

<script>
export default {
  name: 'SubmitQuestion',
  methods: {
    changeColor(){
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor.setAttribute("content", "#FFFFFF");
    }
  },
  created() {
      this.changeColor();
  },
}
</script>


<style lang="stylus">
    @font-face {
        font-family: Caveat;
        src: url('/fonts/Caveat.ttf');
    }
    @font-face {
        font-family: CaveatBrush;
        src: url('/fonts/CaveatBrush.ttf');
    }
    @font-face {
        font-family: Poppins;
        src: url('../../assets/fonts/Poppins-Medium.ttf');
    }
    .img-top{
        position : fixed;
        top:0;
        margin-top:-100px;
        height: 310px;
        width: 430px;
    }
    .bg-frond{
        width : 100%;
        height : 100%;
        position : fixed;
        z-index : 88;
    }
    .text-wrap{
        padding-left:20px;
        font-family : Caveat;
        z-index : 99;
        width : 300px;
        margin : 0 auto;
        text-align :center;
        font-size: 26px;
        color : #fff;
        line-height : 28px;
        font-weight: bold;
        font-style: normal;
    }
    #bg-app {
        width: 100%;
        height: 100%;
        background : #FFFFFF;
        position : fixed;
        z-index : 87;
        padding-top : 120px;
    }
    .bg-buttom{
        position :absolute;
        bottom : 0;
        z-index : 87;
        position : fixed;
        bottom : 0;
        margin-bottom:-70px;
    }
    .bg-center{
        width : 360px;
        height: 294.719px;
        margin-left: auto;
        margin-right: auto;
        display:block;
        margin-top:-40px;
        z-index : 88;
    }
    .btn_show_product{
        z-index:99999999;
        margin-top:-60px;
        width: 100px;
        background: #313D8E !important;
        border: 0px solid #F37679;
    }
    .text_btn{
        color:#FFBD12;
        font-family: Poppins;
    }
</style>